<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card outlined class="primary--border">
                    <v-card-title class="title">
                        ECA Head
                        <v-spacer></v-spacer>
                        <transition name="animate-css-transition" enter-active-class="animated fadeInRight"
                                    leave-active-class="animated fadeOutRight">
                            <add-button permission="exam-create" @action="form.dialog = true, $refs.form ? $refs.form.reset(): '' ">
                                &nbsp; New ECA Head
                            </add-button>
                        </transition>
                    </v-card-title>
                    <v-data-table
                            :headers="headers"
                            :items="form.items.data"
                            :footer-props="footerProps"
                            :options.sync="pagination"
                            :server-items-length="form.items.meta.total"
                            :loading="form.loading">
                        <template  v-slot:item="{index, item}">
                            <tr>
                                <td>{{ index + form.items.meta.from }}</td>
                                <td class="text-xs-left">
                                    {{ item.name }}
                                </td>
                                <td class="text-xs-right">
                                    <view-button permission="exam-read" @click.native="viewECA(item.id)"/>

                                    <edit-button permission="exam-update"
                                                @agree="form.edit(item)"/>
                                    <delete-button permission="exam-delete" @agree="form.delete(item.id)"/>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card>
            </v-flex>
            <v-dialog v-model="form.dialog" persistent max-width="500px">
                <v-card>
                    <v-card-title class="primary white--text">
                        <span class="title">Add/Update</span>
                    </v-card-title>
                    <v-card-text class="pb-1">
                        <v-form ref="form" @submit.prevent="save"
                                @keydown.native="form.errors.clear($event.target.name)" @keyup.enter="save"
                                v-model="valid"
                                :lazy-validation="lazy"
                        >
                            <v-text-field autocomplete="off" label="Name*"
                                          required class="pa-0 pt-3"
                                          v-model="form.name"
                                          name="name" :error-messages="form.errors.get('name')" outlined dense
                                          :rules="[v => !!v || 'Name is required']"/>

                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="warning" text @click="form.dialog = false, form.reset()">Close</v-btn>
                        <v-btn color="success" text @click="validate">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'
    import Mixins from '@/library/Mixins';

    export default {
        mixins: [Mixins],
        data: () => ({
            valid: true,
            lazy: false,
            form: new Form({
                name: '',
            }, '/api/eca-head'),
            search: null,
            rowsPerPageItems: [5, 10, 25, 50, 75],
            pagination: {
                rowsPerPage: 10,
            },
            headers: [
                {text: '#', align: 'center', value: 'id', width: 50, sortable: false},
                {text: 'Name', align: 'left', value: 'name'},
                {text: 'Action', align: 'left', sortable: false, width: 300}
            ],

        }),

        computed: {
            ...mapState(['batch'])
        },

        watch: {
            'pagination': function () {
                this.get();
            },
            'batch': function (value) {
                this.get();
            }
        },

        methods: {
            validate() {
                if (this.$refs.form.validate()) {
                    this.save()
                }
            },
            // queryString() {
            //     let json = this.pagination;
            //     return '?' +
            //         Object.keys(json).map(function (key) {
            //             if (![null, undefined].includes(json[key]))
            //                 return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
            //         }).join('&');
            // },

            get(params) {
                let extraParams = '';
                // let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, this.queryString(extraParams)).then(({data}) => {
                    this.pagination.totalItems = data.meta.total
                })
            },

            save() {
                this

                this.form.store();
            },

            viewECA(id) {
                this.$router.push({
                    name: 'eca-list',
                    params: {
                        ecaHeadId: id
                    }
                })
            },

        }
    }
</script>
<style lang="scss" scoped></style>